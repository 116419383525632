/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter, faInstagram, faDiscord, faTiktok,
} from '@fortawesome/free-brands-svg-icons';
import Fade from 'react-reveal/Fade';
import axios from 'axios';

const Welcome = (props) => {
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [bademail, setBad] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  function validateEmail(email1) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email1);
  }

  const signup = async () => {
    if (!validateEmail(email)) {
      setSuccess(false);
      setBad(true);
    } else {
      setBad(false);
      let response;
      try {
        response = await axios.post('https://nft.goatshedacademy.com/.netlify/functions/email', {
          email,
        });
      } catch (error) {
        console.log('signup failure');
      }
      if (response.data === 'success') {
        setSuccess(true);
        setEmail('');
      }
    }
  };

  return (
    <div className="w-container" id="maincontainer">
      <div id="w-top-wrapper">
        <div id="fighters" />
        <div id="w-top">
          <div id="w-logo" />
          <input placeholder="Email" type="text" value={email} onChange={handleEmailChange} />
          <Fade bottom collapse when={success}>
            <div className="success-message"
            // Boostrap 4 uses some CSS tricks to simplify
            // error handling but we're doing it differently
            // so the next line disables these tricks
              style={{ display: 'block' }}
            >
              You will now recieve project updates!
            </div>
          </Fade>
          <Fade bottom collapse when={bademail}>
            <div className="fail-message"
            // Boostrap 4 uses some CSS tricks to simplify
            // error handling but we're doing it differently
            // so the next line disables these tricks
              style={{ display: 'block' }}
            >
              Please enter a valid email.
            </div>
          </Fade>
          <div id="submit-button" onClick={signup}>
            SUBSCRIBE
          </div>
          <div id="text">
            Sign up with your email to recieve updates about the project and our plans to expand Goat Shed&apos;s digital presence.
          </div>
          <div id="top-socials">
            <a className="socialicon" href="https://www.instagram.com/goatshed/" target="_blank" rel="noopener noreferrer">
              <div className="socialinner">
                <FontAwesomeIcon icon={faInstagram} size="1x" />
              </div>
            </a>
            <a className="socialicon" href="https://twitter.com/goatshedacademy?s=11" target="_blank" rel="noopener noreferrer">
              <div className="socialinner">
                <FontAwesomeIcon icon={faTwitter} size="1x" />
              </div>
            </a>
            <a className="socialicon" href="https://discord.gg/FAFnTzeHfK" target="_blank" rel="noopener noreferrer">
              <div className="socialinner">
                <FontAwesomeIcon icon={faDiscord} size="1x" />
              </div>
            </a>
            <a className="socialicon" href="https://vm.tiktok.com/ZTdPo1Lrb/" target="_blank" rel="noopener noreferrer">
              <div className="socialinner">
                <FontAwesomeIcon icon={faTiktok} size="1x" />
              </div>
            </a>
            <a className="socialicon" href="https://goatshedacademy.com/" target="_blank" rel="noopener noreferrer">
              <div className="socialinner">
                <FontAwesomeIcon icon={['far', 'browser']} size="1x" />
              </div>
            </a>
          </div>

        </div>
      </div>
      <div id="w-bottom">
        <div id="video-wrapper">
          <iframe
            id="video"
            src="https://www.youtube.com/embed/arYztPe0JRE"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
          <div id="goat-left" />
          <div id="goat-right" />
        </div>
        <div id="discord-button">
          JOIN OUR DISCORD
        </div>
        <div id="footer-socials">
          <a className="socialicon" href="https://www.instagram.com/goatshed/" target="_blank" rel="noopener noreferrer">
            <div className="socialinner">
              <FontAwesomeIcon icon={faInstagram} size="1x" />
            </div>
          </a>
          <a className="socialicon" href="https://twitter.com/goatshedacademy?s=11" target="_blank" rel="noopener noreferrer">
            <div className="socialinner">
              <FontAwesomeIcon icon={faTwitter} size="1x" />
            </div>
          </a>
          <a className="socialicon" href="https://discord.gg/FAFnTzeHfK" target="_blank" rel="noopener noreferrer">
            <div className="socialinner">
              <FontAwesomeIcon icon={faDiscord} size="1x" />
            </div>
          </a>
          <a className="socialicon" href="https://vm.tiktok.com/ZTdPo1Lrb/" target="_blank" rel="noopener noreferrer">
            <div className="socialinner">
              <FontAwesomeIcon icon={faTiktok} size="1x" />
            </div>
          </a>
          <a className="socialicon" href="https://goatshedacademy.com/" target="_blank" rel="noopener noreferrer">
            <div className="socialinner">
              <FontAwesomeIcon icon={['far', 'browser']} size="1x" />
            </div>
          </a>
        </div>
        <div id="footer-text">
          Copyright © 2022 Goat Shed Academy. All rights reserved.
        </div>
      </div>
    </div>
  );
};

export default Welcome;
